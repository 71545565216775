.highLine {
    margin-bottom: 10px;
    height: 2px;
    background-color: rgb(207, 75, 149);
    border: none;
    opacity: 0.5;
}

.downLine {
    margin-top: 10px;
    height: 2px;
    background-color: rgb(207, 75, 149);
    border: none;
    opacity: 0.5;
}

.text {
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
}

.img {
    height: 250px;
}


.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40%;
}

@media (max-width:563px) {
    .img {
        height: 130px;
    }
}


@media (max-width:531px) {
    .text {
        font-size: 15px;
        letter-spacing: 0;
    }
}

@media (max-width:476px) {
    .text {
        font-size: 14px;
        letter-spacing: 0;
    }
}

@media (max-width:429px) {
    .img {
        height: 110px;
    }
}

@media (max-width:400px) {
    .text {
        font-size: 13px;
        letter-spacing: 0;
    }
}

@media (max-width:381px) {
    .text {
        font-size: 12px;
        letter-spacing: 0;
    }
}


@media (max-width:375px) {
    .img {
        height: 100px;
    }
}

@media (max-width:360px) {
    .text {
        font-size: 12px;
        letter-spacing: 0;
    }
}

@media (max-width:349px) {
    .text {
        font-size: 11px;
        letter-spacing: 0;
    }
}

@media (max-width:322px) {
    .text {
        font-size: 10px;
        letter-spacing: 0;
    }
}

@media (max-width:292px) {
    .text {
        font-size: 9px;
        letter-spacing: 0;
    }
}