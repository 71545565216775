.section {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1140px;
}

.productImg {
    width: 230px;
    height: 320px;
    object-fit: cover;
    /* object-position: center; */
}

.productsContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.product {
    display: flex;
    flex-direction: column;
    margin: 10px;
    cursor: pointer;
}

.titleProducts {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}

.productTitle {
    margin-top: 5px;
    font-size: 17px;
    color: rgb(207, 75, 149);
    opacity: 0.9;
    font-weight: 500;

}

.productTitle {
    max-width: 230px;
    padding: 0;
    text-align: start;
}

.productType {
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 500;
    padding: 5px 0;
}

.productPrice {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    font-weight: 600;
}

.dramIcon {
    margin-right: 5px;
}

.btnContainer {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.seeAllBtn {
    padding: 10px 30px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    transition: background-color .2s;
    border: 1px solid rgb(55, 55, 55);
    color: rgb(55, 55, 55);
}

.seeAllBtn:hover {
    background-color: #f3efef;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.button {
    font-size: 16px;
    line-height: 22px;
    padding: 11px 24px 13px;
    line-height: 1.1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    flex-shrink: 0;
    /* flex-grow: 1; */
    background-color: rgb(207, 75, 149);
    color: white;
    border: none;
    border-radius: 8px;
    transition: 0.25s;
}

.btnLink {
    color: white !important;
}

@media (max-width:1001px) {
    .productsContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}


@media (max-width:749px) {
    .productsContainer {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}

@media (max-width:740px) {
    .scillsTitle {
        font-size: 30px !important;
    }
}


@media (max-width:522px) {
    .productImg {
        width: 200px;
        height: 300px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 200px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        margin-bottom: 40px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

    .seeAllBtn {
        padding: 6px 25px;
        font-size: 19px;
    }
}


@media (max-width:473px) {
    .scillsTitle {
        font-size: 25px !important;
    }
}

@media (max-width:441px) {
    .productImg {
        width: 170px;
        height: 250px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 170px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 35px;
        margin-bottom: 35px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

}

@media (max-width:426px) {
    .mainTitle {
        font-size: 33px;
    }
}



@media (max-width:380px) {
    .productImg {
        width: 160px;
        height: 230px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 160px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 34px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

.product {
    transition: .3s;
}

.imgContainer {
    overflow: hidden;
    border-radius: 8px;
    position: relative;
}




@media (max-width:360px) {
    .productImg {
        width: 150px;
        height: 220px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 150px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 33px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

    .seeAllBtn {
        padding: 5px 24px;
        font-size: 18px;
    }
}

@media (max-width:335px) {
    .productImg {
        width: 140px;
        height: 210px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 140px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 32px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:332px) {
    .mainTitle {
        font-size: 32px;
    }
}



.imgContainer::before {
    content: "";
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.85) -5.91%, rgba(74, 47, 189, 0.85) 111.58%);
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
    z-index: 999;
}

.imgContainer:hover::before {
    height: 100%;
    z-index: 999;
}

.prodTxtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}

.imgContainer:hover .prodTxtx {
    top: 50%;
    opacity: 1;
    z-index: 1000;
}

.prodTxtx h4 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
    color: white;
}

.prodTxtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.skill {
    padding: 0 0 50px 0;
    position: relative;
}

.skillBx {
    background: white;
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: -60px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.skill .stillsTitle {
    font-size: 45px;
    font-weight: 700;
}

.skillSlider {
    width: 80%;
    margin: 0 auto;
    position: relative;
}

.skillSlider .item img {
    width: 50%;
    margin: 0 auto 15px auto;
}

.backgroundImageLeft {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
}


.scillsTitle {
    font-size: 35px;
    font-weight: 700;
    background-image:
        linear-gradient(to right, indigo, rgb(207, 75, 149));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-animation 10s linear infinite;
    margin-bottom: 50px;
    line-height: 50px;
}

@keyframes rainbow-animation {
    to {
        background-position: 100vh;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: start;
}