.container {
    height: 70px;
    background-color: white;
    border-radius: 0 0 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.wildberriesLogoImg {
    height: 50px;
    width: 26% !important;
    object-fit: contain;
    cursor: pointer;
}

.contentTitle {
    font-size: 16px;
}

@media (max-width:519px) {
    .contentTitle {
        font-size: 14px;
    }
}

@media (max-width:450px) {
    .content {
        flex-direction: column;
        padding-bottom: 10px;
    }

    .wildberriesLogoImg {
        width: 40% !important;
    }

    .contentTitle {
        text-align: center;
    }
}