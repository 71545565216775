.containerWrapper {
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    justify-content: space-evenly;
    margin: 0 0 50px 0;
    height: 100%;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.productDetails {
    max-width: 1200px !important;
    width: 100%;
    background-color: white;
    padding: 16px 30px 16px 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.left {
    width: 45%;
    float: left;
    box-sizing: border-box;
    /* display: flex;
    flex-direction: row-reverse; */
}

.right {
    width: calc(50% - 60px);
    float: right;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}

.name {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    display: inline;
}

.descriptionTitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px !important;
}

.sizeTitleWrapper {
    margin-bottom: 8px;
}

.description {
    overflow-wrap: break-word;
    word-break: break-word;
    font-size: 14px;
    line-height: 20px;
}

.price {
    font-weight: 700;
    text-decoration: none;
    font-size: 28px;
    line-height: 36px;
}

.orderContainer {
    padding: 20px 24px;
    border-radius: 16px;
    margin-bottom: 24px;
    min-height: 200px;
    max-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.priceContainer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.orderButton {
    font-size: 16px;
    line-height: 22px;
    padding: 11px 24px 13px;
    line-height: 1.1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    flex-shrink: 0;
    /* flex-grow: 1; */
    background-color: rgb(207, 75, 149);
    color: white;
    border: none;
    border-radius: 8px;
    transition: 0.25s;
}

.orderButton:hover {
    opacity: 0.9;
}

.oldPrice {
    text-decoration: line-through;
    margin-left: 5px;
    opacity: 0.6;
}

.optionsContainer {
    width: 50%;
}


.options {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.optionsTitle {
    font-weight: 600;
    text-align: left;
    margin-bottom: 4px;
    font-size: 17px;
    line-height: 20px;
    color: rgb(36, 36, 36);
    padding: 8px 10px 8px 10px;
}

.sizeListContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    overflow: hidden;
}

.sizeItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
    position: relative;
    min-width: 42px;
    min-height: 42px;
    padding: 3px 12px 4px;
    border: 1px solid #d3d4dd;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #fff;
    cursor: pointer;
    transition: 0.25s;
}

.sizeItemTitle {
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
}

.sizeItemDetail {
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    color: #868695;
    text-align: center;
    white-space: nowrap;
}

.sizeItem:hover {
    border: 1px solid rgb(207, 75, 149);
}

.activeSize {
    border: 1px solid rgb(207, 75, 149) !important;
}

.nameContainer {
    margin-bottom: 40px;
}

.sizeWrapper {
    margin-bottom: 20px;
}

.crumbsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 40px 0;
    padding-left: 30px;
}

.crumbsHeader {
    width: 1200px !important;
    text-align: start;
}

.colorItemTitle {
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    text-align: center;
    white-space: nowrap;
}

.colorItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    position: relative;
    min-width: 42px;
    min-height: 42px;
    padding: 3px 12px 4px;
    border: 1px solid #d3d4dd;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #fff;
    cursor: pointer;
    transition: 0.25s;
}

.colorItem:hover {
    border: 1px solid rgb(207, 75, 149);
}

.createOrderContainer {
    margin-top: 50px;
}

.activeColor {
    border: 1px solid rgb(207, 75, 149);
}

@media (max-width:469px) {
    .price {
        font-size: 24px;
    }

    .oldPrice {
        font-size: 16px;
    }
}

@media (max-width:400px) {
    .price {
        font-size: 20px;
    }

    .oldPrice {
        font-size: 14px;
    }
}

@media (max-width:358px) {
    .price {
        font-size: 18px;
    }

    .oldPrice {
        font-size: 12px;
    }
}

@media (max-width:554px) {
    .crumbsWrapper {
        display: none;
    }
}

@media (max-width:770px) {
    .productDetails {
        flex-direction: column;
    }

    .left {
        width: 100%;
    }

    .right {
        width: 100%;
        margin: 0;
    }

    .optionsContainer {
        width: 100%;
    }
}



/* COMMENT */

/* .container {
    display: flex;
    justify-content: space-evenly;
    margin: 40px 0 50px 0;
    height: 100%;
}

.productDetails {
    width: 1200px;
    background-color: white;
    padding: 16px 12px 16px 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.left {
    width: calc(100% - 50%);
    float: left;
    box-sizing: border-box;
    margin-right: calc(100% - 95%);
}

.right {
    width: calc(100% - 50%);
    height: calc(100% - 30%);
    float: right;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.name {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    display: inline;
}

.descriptionTitle {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}

.description {
    overflow-wrap: break-word;
    word-break: break-word;
    font-size: 14px;
    line-height: 20px;
}

.price {
    font-weight: 700;
    text-decoration: none;
    font-size: 28px;
    line-height: 36px;
}

.orderContainer {
    padding: 20px 24px;
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    margin-bottom: 24px;
    min-height: 200px;
    max-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.priceContainer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.orderButton {
    font-size: 16px;
    line-height: 22px;
    padding: 11px 24px 13px;
    line-height: 1.1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    flex-shrink: 0;
flex-grow: 1; 
    background-color: rgb(207, 75, 149);
    color: white;
    border: none;
    border-radius: 8px;
    transition: 0.25s;
}

.orderButton:hover {
    opacity: 0.9;
}

.oldPrice {
    text-decoration: line-through;
    margin-left: 5px;
    opacity: 0.6;
}


.options {
    display: flex;
    justify-content: center;
    margin-bottom: 200px;
}

.optionsTitle {
    font-weight: 600;
    text-align: left;
    margin-bottom: 4px;
    font-size: 17px;
    line-height: 20px;
    color: rgb(36, 36, 36);
    padding: 8px 10px 8px 10px;
}

.sizeListContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    margin: 12px 0 12px 0;
}

.sizeItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
    position: relative;
    min-width: 42px;
    min-height: 42px;
    padding: 3px 12px 4px;
    border: 1px solid #d3d4dd;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #fff;
    cursor: pointer;
    transition: 0.25s;
}

.sizeItemTitle {
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
}

.sizeItemDetail {
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    color: #868695;
    text-align: center;
    white-space: nowrap;
}

.sizeItem:hover {
    border: 1px solid rgb(207, 75, 149);
}

.sizeTitle {
    text-decoration: none;
    background-color: transparent;
    color: #555;
    font-size: 14px;
    line-height: 20px;
}

.nameContainer {
    margin-bottom: 20px;
} */