.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 78px;
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    border-bottom: 5px solid rgba(207, 75, 149, 0.7);
    /* position: sticky;
    top: 0;
    z-index: 1;
    height: 78px;
    transition: top 0.5s ease-in-out; */

}

/* .hidden {
    top: -78px;
} */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

}

.list {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.list .item {
    margin-right: 15px;
    color: rgb(67 57 42);
    cursor: pointer;
    font-size: 16px;
}

.list .item:last-child {
    margin-right: 0;
    color: rgb(67 57 42);
}

.img {
    height: 100px;
    cursor: pointer;
    filter: drop-shadow(3px 3px 10px #EE4998);
}

.langContainer {
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(67 57 42);
    font-size: 16px;
}


.imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawerLangContainer {
    display: flex;
    justify-content: flex-start;
}

.drawerItem:first-child {
    margin-top: 50px;
}

.drawerItem {
    color: rgb(67 57 42);
    cursor: pointer;
    font-size: 16px;
    list-style: none;
    margin: 20px;
    margin-bottom: 30px;
    font-weight: 500;
}

.menuButton {
    cursor: pointer;
}

.exitIconContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.exitIcon {
    cursor: pointer;
    transition: .2s ease;
}

.exitIcon:hover {
    transform: scale(1.2);
    color: rgb(55, 55, 55)
}

.drawerItem {
    display: inline-block;
    position: relative;
    color: rgb(67 57 42);
    cursor: pointer;
    font-size: 16px;
    list-style: none;
    margin: 20px;
    margin-bottom: 30px;
    font-weight: 500;
}

.drawerItem:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: rgb(207, 75, 149);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.drawerItem:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.list .item {
    display: inline-block;
    position: relative;
    color: rgb(207, 75, 149) !important;
}

.list .item:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: rgb(207, 75, 149);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.list .item:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}