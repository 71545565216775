.wrapper {
    display: flex;
    justify-content: center;
}

.container {
    width: 85%;
    background: #fff;
    border-radius: 64px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 100px;
}

.container .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container .content .leftSide {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
}

.content .leftSide::before {
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: rgb(207, 75, 149);
}

.content .leftSide .details {
    margin: 14px;
    text-align: center;
}

.content .leftSide .details .icon {
    font-size: 30px;
    color: rgb(207, 75, 149);
    margin-bottom: 10px;
}

.content .leftSide .details .topic {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 8px;
}

.content .leftSide .details .textOne,
.content .leftSide .details .textTwo {
    font-size: 14px;
    color: #afafb6;
    padding: 4px 0 0 0;
}

.container .content .rightSide {
    width: 75%;
    margin-left: 75px;
}

.content .rightSide .topicText {
    font-size: 23px;
    font-weight: 600;
    color: #3e2093;
}

.rightSide .inputBox {
    height: 50px;
    width: 100%;
    margin: 12px 0;
}

.rightSide .inputBox input,
.rightSide .inputBox textarea {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F0F1F8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
}

.rightSide .messageBox {
    min-height: 110px;
}

.rightSide .inputBox textarea {
    padding-top: 6px;
}

.rightSide .button {
    display: inline-block;
    margin-top: 12px;
}

.rightSide .button input[type="button"] {

    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #3e2093;
    cursor: pointer;
    transition: all 0.3s ease;
}


.scillsTitle {
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    background-image:
        linear-gradient(to right, indigo, rgb(207, 75, 149));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-animation 10s linear infinite;
    margin-bottom: 50px;
    line-height: 50px;
}

@keyframes rainbow-animation {
    to {
        background-position: 100vh;
    }
}


.button input[type="button"]:hover {
    background: #5029bc;
}


@media (max-width: 950px) {
    .container {
        width: 90%;
        padding: 30px 40px 40px 35px;
    }

    .container .content .rightSide {
        width: 75%;
        margin-left: 55px;
    }
}

@media (max-width: 820px) {
    .container {
        margin: 40px 0;
        height: 100%;
    }

    .container .content {
        flex-direction: column-reverse;
    }

    .container .content .leftSide {
        width: 100%;
        flex-direction: row;
        margin-top: 40px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .container .content .leftSide::before {
        display: none;
    }

    .container .content .rightSide {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width:740px) {
    .scillsTitle {
        font-size: 30px;
    }
}

@media (max-width:473px) {
    .scillsTitle {
        font-size: 25px;
    }
}

.map {
    width: 100%;
}