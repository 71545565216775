.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10px;
}

/* .emptyBaner {
    color: crimson;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    animation: alternate rotate 8s infinite;
}

@keyframes rotate {
    0% {
        transform: translateX(-100px);
    }

    100% {
        transform: translateX(100px);
    }
} */

.emptyBaner {
    color: crimson;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    animation: rotate 4s infinite alternate;
    transition: transform 0.5s ease-in-out;
}

@keyframes rotate {
    0% {
        transform: translateX(-20px) translateY(-20px);
    }

    25% {
        transform: translateX(-40px) translateY(20px);
    }

    50% {
        transform: translateX(-60px) translateY(-20px);
    }

    75% {
        transform: translateX(-80px) translateY(20px);
    }

    100% {
        transform: translateX(-100px) translateY(-20px);
    }
}