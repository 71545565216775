.productImg {
    width: 230px;
    height: 320px;
    object-fit: cover;
    /* object-position: center; */
}

.productImgFullWidth {
    width: 100% !important;
}

.productsContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.product {
    display: flex;
    flex-direction: column;
    margin: 10px;
    cursor: pointer;
}


.productTitle {
    margin-top: 5px;
    font-size: 17px;
    color: rgb(207, 75, 149);
    opacity: 0.9;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px !important;
}

.productTitle {
    max-width: 230px;
    padding: 0;
    text-align: start;
}

.productType {
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 500;
    padding: 5px 0;
    text-align: start;
}

.productPrice {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    font-weight: 600;
}

.dramIcon {
    margin-right: 5px;
}


@media (max-width:1001px) {
    .productsContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}


@media (max-width:749px) {
    .productsContainer {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}

@media (max-width:522px) {
    .productImg {
        width: 200px;
        height: 300px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 200px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        margin-bottom: 40px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

    .seeAllBtn {
        padding: 6px 25px;
        font-size: 19px;
    }
}

@media (max-width:647px) {
    .mainTitle {
        font-size: 37px;
    }
}

@media (max-width:542px) {
    .mainTitle {
        font-size: 35px;
    }
}


@media (max-width:441px) {
    .productImg {
        width: 170px;
        height: 250px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 170px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 35px;
        margin-bottom: 35px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

}

@media (max-width:426px) {
    .mainTitle {
        font-size: 33px;
    }
}



@media (max-width:380px) {
    .productImg {
        width: 160px;
        height: 230px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 160px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 34px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

.product {
    transition: .3s;
}

.imgContainer {
    overflow: hidden;
    border-radius: 8px;
    position: relative;
}




@media (max-width:360px) {
    .productImg {
        width: 150px;
        height: 220px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 150px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 33px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

    .seeAllBtn {
        padding: 5px 24px;
        font-size: 18px;
    }
}

@media (max-width:335px) {
    .productImg {
        width: 140px;
        height: 210px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 140px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 32px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:332px) {
    .mainTitle {
        font-size: 32px;
    }
}



.imgContainer::before {
    content: "";
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.85) -5.91%, rgba(74, 47, 189, 0.85) 111.58%);
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
    z-index: 999;
}

.imgContainer:hover::before {
    height: 100%;
    z-index: 999;
}

.prodTxtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}

.imgContainer:hover .prodTxtx {
    top: 50%;
    opacity: 1;
    z-index: 1000;
}

.prodTxtx h4 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
    color: white;
}

.prodTxtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.backgroundImageLeft {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
}